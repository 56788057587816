/* http://meyerweb.com/eric/tools/css/reset/
        v2.0 | 20110126
        License: none (public domain)
      */

base,
basefont,
datalist,
head,
meta,
script,
style,
title,
noembed,
param,
template {
	display: none;
}

body {
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 300;
	line-height: 25px;
	color: #333;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}

body * {
	box-sizing: border-box;
}

#root {
	height: 100%;
}
